/** @jsx jsx */
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Box, jsx } from "theme-ui";
import React, { useCallback } from "react";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const pageContent =
  '<gatsby_donation amount="0" btntext="Proceed to Registration" donationid="15"' +
  'projectid="" btnbg="#4d8f15" colortext="black" colorprimary="forestgreen"' +
  'colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="General Donation SRCM, USA offline"/>';
const PageContent = React.memo(PageContentNonMemoized);

const SrcmUSAOffline = () => {
  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers("srcm-usa-general-donation-offline", event),
    []
  );

  return (
    <LayoutHome
      seoTitle="General Donation to SRCM, USA offline – Donations"
      contentHeaderStyle="contentHeaderText"
      contentHeader={<h3>Heartfulness Donations</h3>}
    >
      <Box>
        <h3 sx={{ mb: 3, mt: 3 }}>General Donation to SRCM, USA offline</h3>
        <p>
          All donations to Shri Ram Chandra Mission are voluntary. Shri Ram
          Chandra Mission is registered as a tax-exempt organization under
          section 501-C3 of the Internal Revenue Code. Donated funds are used
          for:
        </p>
        <ul>
          <li>
            Maintenance and support of existing ashrams and meditation centers
            across USA as well as the building and establishment of new SRCM
            facilities.
          </li>
          <li>
            Hosting of seminars, training courses, and other activities at SRCM
            facilities.
          </li>
          <li>
            Printing and distribution of publications and other multimedia
            content.
          </li>
          <li>
            Support research, education and training courses to promote Sahaj
            Marg Heartfulness meditation and practice.
          </li>
        </ul>
        <br />
        Please contact{" "}
        <a href="mailto:us.accounts@srcm.org">us.accounts@srcm.org</a> for
        questions about SRCM donations.
        <PageContent eventCallback={eventCallback} pageContent={pageContent} />
      </Box>
    </LayoutHome>
  );
};

export default SrcmUSAOffline;
